import React, { useState } from "react";
import axios from "axios";
import "./style.css"



function Contact() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [message, setMessage] = useState("")

    function handleName(event) {
      setName(event.target.value)
    }

    function handleEmail(event) {
      setEmail(event.target.value)
    }

    function handleCompany(event) {
      setCompany(event.target.value)
    }

    function handleMessage(event) {
      setMessage(event.target.value)
    }

    function handleSubmit(event) {
      event.preventDefault()
      console.log(name)
      console.log(email)
      console.log(message)

      const emailData = {
        name: name,
        email: email,
        company: company,
        message: message
      }


      axios.post('/send', emailData)

      setName("")
      setEmail("")
      setCompany("")
      setMessage("")

      alert("Thanks for your message! We will respond as soon as possible.")

    }
  return (
    <div className="content">
    <div className="descriptionBackground">
    <div className="appDescription">
        <h3>Drop a Line...</h3>
        <hr />
        <p>Please email <a className="contactLink" href="mailto:info@pennaz.com">info@pennaz.com</a> with your questions and project details or fill out the contact form below and we will respond as soon as we can.</p>
    </div>
    </div>
        <div className="container p-3 my-3">
          <div className="contactCard card">
          <h3>Contact Form</h3>
          <hr></hr>
          <form action="/action_page.php">
            <div className="form-group">
              <label for="name">Name:</label>
              <input type="name" className="form-control" placeholder="Enter Full Name" id="name" onChange={handleName} value={name} />
            </div>
            <div className="form-group">
              <label for="email">Email Address:</label>
              <input type="email" className="form-control" placeholder="Enter Email" id="email" onChange={handleEmail} value={email} />
            </div>
            <div className="form-group">
              <label for="company">Company:</label>
              <input type="company" className="form-control" placeholder="Enter Company (Optional)" id="company" onChange={handleCompany} value={company} />
            </div>
            <div className="form-group">
              <label for="message">Message:</label>
              <textarea className="form-control" id="message" rows="5" placeholder="Enter Message" onChange={handleMessage} value={message} />
            </div>
            <button type="submit" className="btn btn-dark" onClick={handleSubmit}>Submit</button>
          </form>
          </div>
        </div>
        </div>
  )
}

export default Contact;