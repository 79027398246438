import React from "react";
import $ from "jquery";
import jQuery from "jquery";
import golfBackground from '../../image/golfBackground3.jpg';

function GolfPage() {

    var sectionStyle = {
        // width: "100%",
        // height: "400px",
        backgroundImage: `url(${golfBackground})`,
        height: "500px", 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };

    function showImages(el) {
        var windowHeight = jQuery(window).height();
        $(el).each(function(){
            var thisPos = $(this).offset().top;

            var topOfWindow = $(window).scrollTop();
            if (topOfWindow + windowHeight - 200 > thisPos ) {
                $(this).addClass("fadeIn");
            }
        });
    }

    // if the image in the window of browser when the page is loaded, show that image
    $(document).ready(function(){
            showImages('.appPics');
    });

    // if the image in the window of browser when scrolling the page, show that image
    $(window).scroll(function() {
            showImages('.appPics');
    });

    return (
        <div className="content">
            <section className="appTitle row" style={ sectionStyle }>
                <h3 id="appName">GOLF-A-THON</h3>
                <h6 id="subTitle">Compete with Friends</h6>
            </section>
            <div className="descriptionBackground row">
            <div className="appDescription">
                <p>Whether you want to keep score to improve your handicap or compete with friends, GOLF-A-THON offers a simple user interface for quick use on the course and reliable data management to save and share your stats. The app can help organize fun golf challenges (games) between your friends and utilize data from your USGA GHIN handicap account to automatically determine competition results. You can challenge your friends to decrease their handicap over a season, see who can achieve the lowest Eclectic score over a season, and create a multi-day team tournament during your next golf get-away.</p>
            </div>
            </div>
            <div className="screenBackground row">
            <div className="appPics row">
                <img className="golfScreen mx-auto" src="../../images/Golf/golfHero.png" alt="Golf"/>
                <img className="golfScreen mx-auto" src="../../images/Golf/GAT_Challenges.png" alt="Challenges"/>
                <img className="golfScreen mx-auto" src="../../images/Golf/GAT_ChallengeTypes.png" alt="Challenge Types"/>
                <img className="golfScreen mx-auto" src="../../images/Golf/GAT_Commissioner.png" alt="Commissioner"/>
                <img className="golfScreen mx-auto" src="../../images/Golf/GAT_CreateTeams.png" alt="Create Teams"/>
                <img className="golfScreen mx-auto" src="../../images/Golf/GAT_Forecast.png" alt="Handicap Forecast"/>
                <img className="golfScreen mx-auto" src="../../images/Golf/GAT_RoundDetails.png" alt="Round Details"/>
            </div>
            </div>
            <div className="descriptionBackground row">
            <div className="appDetails row">
            <div className="space col-lg-2"></div>
                <div className="appBullets col-lg-4">
                    <h5>Features</h5>
                    <ul>
                        <li>Personal handicap analysis</li>
                        <li>Play different game types such as Handicap Improvement, Eclectic (Ringers Tournament) with skins or multi-team tournaments utilizing Stableford scoring</li>
                        <li>Create challenges that can span one day or an entire year and include an unlimited amount of players</li>
                        <li>Receive notifications of handicap changes and other insights from your logged rounds</li>
                        <li>Automatic game payout calculation for tournaments and competitions</li>
                    </ul>
                    </div>
                    <div className="appBullets col-lg-4">
                    <h5>Challenges</h5>
                    <ul>
                        <li>
                            <a className="golfLinks" href="golf-a-thon/handicap">Handicap Improvement</a>
                        </li>
                        <li>
                            <a className="golfLinks" href="golf-a-thon/eclectic">Eclectic (Ringers Tournament)</a>
                        </li>
                        <li>
                            <a className="golfLinks" href="golf-a-thon/tournament">Multi-Team Tournaments</a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="space col-lg-2"></div>
            </div>
            </div>



        
    )
}

export default GolfPage;