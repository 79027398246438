import React from "react"
import "./style.css"

function Tournament() {

    return (
        <div className="content">
        <div className="descriptionBackground">
            <div className="appDescription">
                <h1>Team Tournament Challenge</h1>
                <hr />
                <p>The Team Tournament Challenge provides a way to calculate team scores for 1 or more rounds of golf. This is a great option for golf vacations, ball drops or couples tournaments. Rounds can be played on different courses and from different tees.</p>
            </div>
        </div>
        <div className="gameDetails">
            <img className="heroImg" src="../../images/Golf/GAT_CreateTeams.png" alt="Create Teams"/>
            <h3 className="challengeSub">Background</h3>
            <p>This challenge is largely modeled after college tournaments where you have multiple teams playing and you take only the best scores from a subset of the players on the team.</p>
            <h3 className="challengeSub">Game Details</h3>
            <p>A timeframe for the challenge is determined and any round played within this time period is applied to the challenge. The commissioner enters all the players and assigns players to each team. Players can also be assigned to teams randomly or distributed evenly based on handicap index. Any number of teams can be created with any number of players. The commissioner can also decide how many scores for each team are used or discarded. <a href="https://en.wikipedia.org/wiki/Stableford">Stableford scoring</a> is used which is a great format for limiting high scores on holes. Results are grouped by date for each team along with a total score for all rounds.</p>
            <h3 className="challengeSub">Attaining Victory</h3>
            <p>Victory is simple…have one of the highest team point totals.</p>
            
            <h3 className="challengeSub">Payouts</h3>
            
            <h5>Places</h5>
            <p>Places – The pot is allocated to 1st, 2nd and 3rd place (Gross and Net) depending on which team has the highest total Stableford score. </p>
            
            <h3 className="challengeSub">Scoring</h3>
            <p>Over the course of the challenge, players must report their hole-by-hole scores into the GHIN app or website. It is imperative that hole-by-hole scores be entered into the GHIN app or website since Stableford scores are calculated per hole. Failure to do so will result in no score for that person. Each night, the GOLF-A-THON servers will tabulate scores for all players and assemble the results in a leaderboard which can be viewed on the Challenges tab of the app.</p>
        </div>
        <div className="faq">
            <h3 className="challengeSub">Frequently Asked Questions</h3>
            <br/>
            <p className="q">Q: I entered my total score into GHIN instead of using hole-by-hole entry. What can I do?</p>
            <p>A: Perhaps the best option would be to enter your score a second time using hole-by-hole data and then contacting your golf pro to request they delete the bad entry. This is very easy for them to do.</p>
        </div>

    </div>
    )
}

export default Tournament;