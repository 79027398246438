import React from "react";
//import { Link, useLocation } from "react-router-dom";

function AppCard(props) {

  // const location = useLocation();

  return (
    <div className="appContainer col-lg-12 col-12">
        <div className="app select card">
        <a href={props.link} className="btn stretched-link">
          <div className="titles">
            <h3 className="card-title">{props.name}</h3>
            <h6>{props.subTitle}</h6>
          </div>
          <hr></hr>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3">
                <img className="card-img-top" src={props.image} alt={props.name} />
              </div>
              <div className="appDes col-lg-9">
                <p>{props.description}</p>
              </div>
            </div>
          </div>
          </a>
        </div>
    </div>
  );
}

export default AppCard;