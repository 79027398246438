import React from "react";
import "./style.css";

function GolfFeedback() {

    return (
        <div className="content">
    <div className="descriptionBackground">
    <div className="appDescription">
        <h3>App Feedback</h3>
        <hr />
        <p>Review your user comments and feedback through the portal below</p>
    </div>
    </div>

    <div className="container p-3 my-3">
          <div className="contactCard card">
          <h1>Feedback</h1>
          <hr></hr>
          
          </div>
        </div>

    </div>
    )
}
export default GolfFeedback;