import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home"
import Consulting from "./pages/Consulting"
import Apps from "./pages/Apps"
import Contact from "./pages/Contact"
import Admin from "./pages/Admin"
import Handicap from "./pages/Handicap"
import Eclectic from "./pages/Eclectic"
import Tournament from "./pages/Tournament"
import GolfPrivacy from "./pages/GolfPrivacy"
import GolfEULA from "./pages/GolfEULA"
import Navbar from "./components/Navbar"
import ShuffleEULA from "./pages/ShuffleEULA"
import ShufflePrivacy from "./pages/ShufflePrivacy"
import GolfPage from "./pages/GolfPage"
import ShufflePage from "./pages/ShufflePage"
import GolfFeedback from "./pages/GolfFeedback"
import Scores from "./pages/Scores"

import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route exact path={["/"]}>
            <Home />
          </Route>
          <Route exact path="/consulting">
            <Consulting />
          </Route>
          <Route exact path="/apps/">
            <Apps />
          </Route>
          <Route exact path="/apps/golf-a-thon">
            <GolfPage />
          </Route>
          <Route exact path="/apps/shufflescore">
            <ShufflePage />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/admin">
            <Admin />
          </Route>
          <Route exact path="/apps/golf-a-thon/eclectic">
            <Eclectic />
          </Route>
          <Route exact path="/apps/golf-a-thon/handicap">
            <Handicap />
          </Route>
          <Route exact path="/apps/golf-a-thon/tournament">
            <Tournament />
          </Route>
          <Route exact path="/apps/golf-a-thon/privacy">
            <GolfPrivacy />
          </Route>
          <Route exact path="/apps/golf-a-thon/eula">
            <GolfEULA />
          </Route>
          <Route exact path="/apps/shufflescore/eula">
            <ShuffleEULA />
          </Route>
          <Route exact path="/apps/shufflescore/privacy">
            <ShufflePrivacy />
          </Route>
          <Route exact path="/apps/golf-a-thon/feedback">
            <GolfFeedback />
          </Route>
          <Route exact path="/apps/golf-a-thon/scores">
            <Scores />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
