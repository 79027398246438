import React from "react"
import "./style.css"

function Eclectic() {

    return (
        <div className="content">
            <div className="descriptionBackground">
                <div className="appDescription">
                    <h1>Eclectic Challenge Details</h1>
                    <hr />
                    <p>The Eclectic Challenge (aka, Ringers Tournament) is a challenge in which you play the same course and tee box over a period of time. Your score is calculated by taking the lowest net score you achieved on each hole. So if you played 20 rounds and your lowest score on hole #1 was a 3, then your score for that hole would be a 3.</p>
                </div>
            </div>


            <div className="gameDetails">
                <img className="heroImg" src="../../images/Golf/EclecticDetails.png" alt="Eclectic Details"/>
                <h3 className="challengeSub">Background</h3>
                <p>Keeping track of Eclectic scores has long been a common game played at courses throughout the world. Many people even maintain individual Eclectics over the course of a year as well as a lifetime. Eclectics are a common game tabulated by men’s and women’s groups at golf courses as they provide a fun way of measuring performance over a period of time instead of just one round.</p>
                <h3 className="challengeSub">Game Details</h3>
                <p>A timeframe for the challenge is determined…this might be a few days over the course of a weekend or perhaps the entire golf season. Additionally, a specific course and tee are defined. Only rounds played on that same tee will be counted toward ones Eclectic scorecard. Only net scores are used in this challenge, however, gross scores are displayed in case you wanted to use that information for additional payouts. The leaderboard displays the scores for all players in the challenge. In addition to the leaderboard, each scorecard within it can be clicked-on to reveal additional details about the Eclectic scores such as the date the score was achieved. You can also easily determine which holes you need to focus on to decrease your score.</p>
                <p>In addition to the normal Eclectic, you can optionally play a skins game. A skin is awarded to the sole person who has the lowest net score for a given hole. Skins are denoted in the leaderboard with a score in a different colored font than all others.</p>
                <h3 className="challengeSub">Attaining Victory</h3>
                <p>Victory is simple…have one of the lowest total net scores.</p>
                
                <h3 className="challengeSub">Payouts</h3>
                
                <h5>Places</h5>
                <p>The pot is allocated to 1st, 2nd and 3rd place depending on who has the lowest score. Ties are broken by using the lowest gross score.</p>
                <h5>DFL (Dead Frickin' Last)</h5>
                <p>Optionally, a percentage of the pot can be allocated to the player finishing in last place. This is a common courtesy as they likely need money for golf lessons or to drown their sorrows.</p>
                
                <h3 className="challengeSub">Scoring</h3>
                <p>Over the course of the challenge, players must report their hole-by-hole scores into the GHIN app or website. Only scores for the configured tees will be applied to the challenge. Each night, the GOLF-A-THON servers will tabulate scores for all players and assemble the results in a leaderboard which can be viewed on the Challenges tab of the app.</p>
                <p>The scores for each round are based off the handicap index a person had during that round. </p>
            </div>
            <div className="faq">
                <h3 className="challengeSub">Frequently Asked Questions</h3>
                <br/>
                <p className="q">Q: Is there any additional rules for determining skins such as a gross gross beating a net score in the case of a tie?</p>
                <p>A: No. Results are calculated using only net scores.</p>
                <hr />
                <p className="q">Q: If I add somebody to the challenge after it has started, do any of their previous scores count or does the app only look new scores since the day they joined?</p>
                <p>A: Each night, the GOLF-A-THON servers re-run each persons eclectic scores from the start date of the challenge up to the current day. We do it this way so that we catch any modifications made to scores. Therefore, new people that join get the benefit of using their past scores.</p>
                <hr />
                <p className="q">Q: I just finished my round, is there a way I can see how I did immediately?</p>
                <p>A: Yes! After you have completed your round and entered your hole-by-hole scores into the GHIN app or website you can request a score update. To do so, navigate to the leaderboard for the challenge and in the upper-right corner is a link to “Update Scores”…simply tap the link. Please note that this will update only your score.</p>
            </div>

        </div>
    )
}

export default Eclectic;