import React from "react"

function Admin() {

    return (
        <div>
            <h1>Admin Page (hidden/password protected)</h1>
            <ul>
                <li>Feedback page - list all feedback form GOLF-A-THON DB</li>
            </ul>
        </div>
    )
}

export default Admin;