import React from 'react';

const Scorecard = ({ groupScoring }) => {
  return (
    <table>
      <tbody>
        <tr>
          <td className="hole" style={{width: 85}}>Hole</td>
          <td className="hole">1</td>
          <td className="hole">2</td>
          <td className="hole">3</td>
          <td className="hole">4</td>
          <td className="hole">5</td>
          <td className="hole">6</td>
          <td className="hole">7</td>
          <td className="hole">8</td>
          <td className="hole">9</td>
          <td className="hole" style={{width: 25}}>Out</td>
          <td style={{width: 2}}></td>
          <td className="hole">10</td>
          <td className="hole">11</td>
          <td className="hole">12</td>
          <td className="hole">13</td>
          <td className="hole">14</td>
          <td className="hole">15</td>
          <td className="hole">16</td>
          <td className="hole">17</td>
          <td className="hole">18</td>
          <td className="hole" style={{width: 25}}>In</td>
          <td className="hole" style={{width: 30}}>Total</td>
        </tr>

        <tr>
          <td className="par" style={{width: 85}}>Par</td>
          { groupScoring.players[0].scores.slice(0, 9).map((hole) =>
            <td key={hole.holeNumber} className="par"> { hole.par }</td>
          )}

          <td className="par" style={{width: 25}}>36</td>
          
          <td style={{width: 2}}></td>

          { groupScoring.players[0].scores.slice(9, 18).map((hole) =>
            <td key={ hole.strokeAllocation} className="par"> { hole.par }</td>
          )}

          <td className="par" style={{width: 25}}>36</td>
          <td className="par" style={{width: 30}}>72</td>
        </tr>

        { groupScoring.players.map( (gs, index) => 
          <tr key={[gs.name, '-', index].join()}>
            <td className="scoreTitle"> { gs.lastName } - { gs.gameIndex.total }</td>

            { gs.scores.slice(0, 9).map((hole) =>
              <td key={hole.holeNumber} className="score"> { hole.gross > 0 ? hole.gross : '' } 
              <div className="scoreTeam"> {hole.teamPoints >= 9999 ? 'w' : hole.teamPoints > 0 ? hole.teamPoints : ''} </div>
              <div className="scoreSpecial"> {hole.specialPoints > 0 ? hole.specialPoints : ''} </div>
              <div className="scorePops"> {hole.pops === 0 ? '' : hole.pops === 1 ? '.' : hole.pops === 2 ? '..' : '...'} </div>
              </td>
            )}

            <td className="score" style={{width: 25}}>
              <div className="scoreTotal"> { gs.outNet } </div>

            </td>
            <td style={{width: 2}}></td>

            { gs.scores.slice(9, 18).map((hole) =>
              <td key={hole.holeNumber} className="score"> { hole.gross > 0 ? hole.gross : '' } 
                <div className="scoreTeam"> { hole.teamPoints >= 9999 ? 'w' : hole.teamPoints > 0 ? hole.teamPoints : ''} </div>
                <div className="scoreSpecial"> {hole.specialPoints > 0 ? hole.specialPoints : ''} </div>
                <div className="scorePops"> {hole.pops === 0 ? '' : hole.pops === 1 ? '.' : hole.pops === 2 ? '..' : '...'} </div>
              </td>
            )}

            <td className="score" style={{width: 25}}>{ gs.inNet }</td>
            <td className="score" style={{width: 30}}>
              <div className="scoreTotal"> { gs.totalNet } </div>
              <div className="scoreTeam"> { gs.gamePoints >= 9999 ? '' : gs.gamePoints > 0 ? gs.gamePoints : '' } </div>
              <div className="scoreSpecial" style={{left: 19}}> { gs.specialPoints} </div>
              </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default Scorecard