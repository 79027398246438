import React from "react"
// import PennazLogo from "./PennazLogo.png"
import PennazLogo from "../../image/PennazLogo.png"
import Background from "../../image/homeBkgrd.png"
import $ from "jquery";
import jQuery from "jquery";
import "./style.css"

function Home() {

    var sectionStyle = {
        backgroundImage: `url(${Background})`,
        height: "1000px",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    }

    function showFirstImages(el) {
        var windowHeight = jQuery(window).height();
        $(el).each(function(){
            var thisPos = $(this).offset().top;

            var topOfWindow = $(window).scrollTop();
            if (topOfWindow + windowHeight - 200 > thisPos ) {
                $(this).addClass("fadeIn");
            }
        });
    }

    function showSecondImages(el) {
        var windowHeight = jQuery(window).height();
        $(el).each(function(){
            var thisPos = $(this).offset().top;

            var topOfWindow = $(window).scrollTop();
            if (topOfWindow + windowHeight - 200 > thisPos ) {
                $(this).addClass("fadeIn1");
            }
        });
    }

    function showThirdImages(el) {
        var windowHeight = jQuery(window).height();
        $(el).each(function(){
            var thisPos = $(this).offset().top;

            var topOfWindow = $(window).scrollTop();
            if (topOfWindow + windowHeight - 200 > thisPos ) {
                $(this).addClass("fadeIn2");
            }
        });
    }

        // if the image in the window of browser when the page is loaded, show that image
        $(document).ready(function(){
            showFirstImages('.home');
            showSecondImages('.topLogos');
            showThirdImages('.homeBackground')
    });

        // if the image in the window of browser when scrolling the page, show that image
        $(window).scroll(function() {
            showFirstImages('.home');
            showSecondImages('.topLogos');
            showThirdImages('.homeBackground')
    });





    // if the image in the window of browser when scrolling the page, show that image




    return (
        <div className="home" style={sectionStyle}>
            <div className="topLogos">
            <img className="logo" src={PennazLogo} alt="Pennaz Logo" />
                <div className="homeName row">
                    <h1>Pennaz Software</h1>
                </div>
                </div>
            <div className="homeBackground">
                <div className="homeCopy">
                    <h3>Combining the Power of Technology and the Joy of Sports</h3>
                    <hr/>
                    <p>Offering consulting and app development services in a wide range of technologies</p>
                </div>
            </div>
        </div>

    )
}

export default Home;