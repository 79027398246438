import React from "react";
import { Link, useLocation } from "react-router-dom";
import PennazLogo from "../../image/PennazLogo.png"

function Navbar() {

    const location = useLocation();

    return (

        <nav className="navbar navbar-expand-sm navbar-light bg-light">
            <img src={PennazLogo} alt="Pennaz Logo" width="50"/>
            <Link to="/" className={location.pathname === "/" ? "nav-link active navbar-brand" : "nav-link navbar-brand"}>
                Pennaz Software
          </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav navbar-right">
                    <li className="nav-item">
                        <Link to="/consulting" className={location.pathname === "/consulting" ? "nav-link active" : "nav-link"}>
                            Consulting
          </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/apps"
                            className={location.pathname === "/apps" ? "nav-link active" : "nav-link"}
                        >
                            Apps
          </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact" className={location.pathname === "/contact" ? "nav-link active" : "nav-link"}>
                            Contact Us
          </Link>
                    </li>
                
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
