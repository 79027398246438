import React, { Component } from "react";
import AppCard from "../../components/AppCard";
import appOverview from "../../components/appOverview.json";
import "./style.css"


class Apps extends Component {

    state = {
        appOverview
    }

    render() {
    return (
        <div className="content">
            <div className="descriptionBackground">
            <div className="appDescription">
                <h3>Check out our deployed applications...</h3>
                <hr />
                <p>While we have years of experience working on enterprise solutions at Fortune 100 companies, we are constantly looking for fun and exciting ways that technology can enhance our passions and hobbies.</p>
            </div>
            </div>
            <br />
           <div className="row">
               {this.state.appOverview.map(app => (
                   <AppCard
                        name={app.name}
                        subTitle={app.subTitle}
                        image={app.image}
                        description={app.description}
                        link={app.link}
                        />
               ))}
           </div>

        </div>
    )
}
}

export default Apps;