import React from "react"
import "./style.css"

function Handicap() {

    return (
        <div className="content">
            <div className="descriptionBackground">
                <div className="appDescription">
                    <h1>Handicap Improvement Challenge</h1>
                    <hr />
                    <p>The Handicap Improvement Challenge is all about improving your handicap index over a period of time. Everybody in the game commits to improving by a designated percentage of their index at the beginning of the period. If you achieve your target then you win.</p>
                </div>
            </div>
            <div className="gameDetails">
                <img className="heroImg" src="../../images/Golf/HandicapImpDetails.png" alt="Handicap Improvement Details"/>
                <h3 className="challengeSub">Background</h3>
                <p>This game was born out of an annual challenge that members at Bear Creek Country Club in Woodinville, WA have been playing for some time. It started small with just a couple of people and has grown every year. The premise is that we all want to improve our game and lowering one’s handicap is the best way to measure success. Now that the USGA handicap system has changed to include daily updates, the feedback mechanism for how well we are playing is very tight. GOLF-A-THON has taken this challenge to the next level by providing an easy way to track everyone’s daily progress, creating a vehicle to root players on and of course talk smack.</p>
                <h3 className="challengeSub">Game Details</h3>
                <p>A timeframe for improvement is selected (Bear Creek selected their main playing season: March – October) and then a set improvement rate is determined (for example, 20%). Whatever your handicap is on the first day of the challenge is your “base” handicap and is used to determine your handicap goal. For example, if the challenge has a 20% improvement rate and your base handicap index is a 10.0 then your goal would be an index of 8.0.</p>
                <h3 className="challengeSub">Attaining Victory</h3>
                <p>Victory is achieved by meeting or beating your handicap index goal at any point throughout the challenge period.</p>
                
                <h3 className="challengeSub">Payouts</h3>
                
                <h5>Split the Pot</h5>
                <p>The pot is split between everybody that achieves the goal</p>
                <h5>Places</h5>
                <p>The pot is allocated to 1st, 2nd and 3rd place depending on who reaches the goal first.</p>
                <h5>DFL (Dead Frickin' Last)</h5>
                <p>Optionally, a percentage of the pot can be allocated to the player finishing in last place. This is a common courtesy as they likely need money for golf lessons or to drown their sorrows.</p>
                
                <h3 className="challengeSub">Scoring</h3>
                <p>Handicap results are tabulated every night after GHIN has processed their revisions. Results appear on the Challenges tab.</p>
            </div>
            <div className="faq">
                <h3 className="challengeSub">Frequently Asked Questions</h3>
                <br/>
                <p className="q">Q: What if somebody has a really low index (like a 2.1) and ends up achieving the goal after playing only one round? This doesn’t seem fair.</p>
                <p>A: That person will be awarded a victory. We suggest that you consider the handicaps of the players you let into the game. We are considering adding a feature that would allow you to stipulate a minimum number of rounds that can be completed before somebody can win. However, our experience shows this isn’t a common scenario.</p>
                <hr />
                <p className="q">Q: What if somebody is a sandbagger and tries to game-the-system by playing poorly right before the challenge starts?</p>
                <p>A: Sandbagging is a way of life for some that we all must deal with. While we could have created some advanced sandbagger algorithms to rid ourselves of these vile creatures, we decided it was best left for you to deal with. Good luck!</p>
            </div>

        </div>
    )
}

export default Handicap;