import React from "react"
import "./style.css"
import $ from "jquery";
import jQuery from "jquery";
import consultingBackground from '../../image/consultingBackground.jpg';

function Consulting() {

    var sectionStyle = {
        backgroundImage: `url(${consultingBackground})`,  
        height: "600px",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    }

    function showImages(el) {
        var windowHeight = jQuery(window).height();
        $(el).each(function(){
            var thisPos = $(this).offset().top;

            var topOfWindow = $(window).scrollTop();
            if (topOfWindow + windowHeight - 200 > thisPos ) {
                $(this).addClass("fadeIn");
            }
        });
    }

    // if the image in the window of browser when the page is loaded, show that image
    $(document).ready(function(){
            showImages('.duties');
    });

    // if the image in the window of browser when scrolling the page, show that image
    $(window).scroll(function() {
            showImages('.duties');
    });

    return (
        <div className="content">
            <div className="topContent row">
            <div className="topImage col-lg-4">
                    <img className="consultingImg" src="../../images/Golf/golfHero.png" alt="Consulting"/>
                </div>
                <div className="topCopy col-lg-8">
                    <h3>How can we help?</h3>
                    <hr />
                    <p>We are software experts who are passionate about building fun and engaging user experiences for your business. Our team brings years of experience as developers and program managers in the technology space and have worked on several high-fidelity applications that have reached millions of users. </p>
                </div>

            </div>

            <div className="paralax" style={ sectionStyle }>
                <div className="row duties">
                <h4>We offer a range of technical services to support your projects:</h4>
                <ul>
                    <li>App development</li>
                    <li>Web development</li>
                    <li>Mobile development </li>
                    <li>Custom software</li>
                    <li>Agile product management</li>
                </ul>
                </div>
            </div>

            <div className="bottom">
                <h3>Ready to bring your idea to life?</h3>
                <p><a className="contactLink" href="/contact">Contact us</a> with your details and we will reach out to you right away.</p>
            </div>
        </div>
    )
}

export default Consulting;