import React from 'react';
import Scorecard from '../../components/Scorecard.js';
import axios from 'axios';
import "./style.css";
import queryString from 'query-string';

class Scores extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            groupScoring: {
                players: [
                        {
                            name: '',
                            scores: [
                                {
                                    holeNumber: 0,
                                    strokeAllocation: 0,
                                    gross: 0,
                                    net: 0,
                                    par: 0,
                                    specials: [
                                        {
                                            rawValue: 0
                                        }
                                    ],
                                    pops: 0,
                                    teamPoints: 0,
                                    specialPoints: 0,
                                    teammates: []
                                }
                            ],
                            teeSetName: '',
                            outGross: 0,
                            inGross: 0,
                            totalNet: 0,
                            gamePoints: 0,
                            specialPoints: 0,
                            totalMoney: 0,
                            gameIndex: {
                                front: 0,
                                back: 0,
                                total: 0
                            }
                        }
                ]
            }
        };
    }
    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        let roundID = parsed.roundID
        if (roundID === "") {
            console.error('An invalid RoundID was specified')
            return
        }

        const apiToken = process.env.API_KEY
        let config = {'x-api-key': apiToken, 'Content-Type': 'application/json'};
        const newLocal = `https://bqacz0qthg.execute-api.us-west-2.amazonaws.com/dev?roundID=` + roundID;
        axios.get(newLocal, {headers: config})
        .then(response => {
            const gsData = response.data;
            console.log('Server Data: ', gsData)
            this.setState({ groupScoring: response.data.groupScoring })})
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });

    }

    render() {
        const { groupScoring } = this.state;
        return (
            <div className="content">
                { this.state.errorMessage && <h3 className="error">The scoreboard for this game is not available - { this.state.errorMessage } </h3> }
                <div className="container p-3 my-3">
                    <Scorecard groupScoring={ groupScoring } />
                </div>
                <div className="scoreKeyTitle">Key:</div>
                <div className="scoreKey">gross
                    <div className="scoreTeam" style={{top: 10, left: 0, fontSize: 7}}>points</div>
                    <div className="scoreSpecial" style={{top: 2, left: 30, fontSize: 7}}>specials</div>
                    <div className="scorePops" style={{top: -53, left: 2, fontSize: 7}}>strokes</div>
                </div>
            </div>
        )
    }
}

export default Scores;