import React from "react"
import $ from "jquery";
import jQuery from "jquery";
import shuffleBackground from '../../image/shuffleBackground.jpeg';


function ShufflePage() {

    var sectionStyle = {
        // width: "100%",
        // height: "400px",
        backgroundImage: `url(${shuffleBackground})`,
        height: "500px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };

    function showImages(el) {
        var windowHeight = jQuery(window).height();
        $(el).each(function () {
            var thisPos = $(this).offset().top;

            var topOfWindow = $(window).scrollTop();
            if (topOfWindow + windowHeight - 200 > thisPos) {
                $(this).addClass("fadeIn");
            }
        });
    }

    // if the image in the window of browser when the page is loaded, show that image
    $(document).ready(function () {
        showImages('.appPics');
    });

    // if the image in the window of browser when scrolling the page, show that image
    $(window).scroll(function () {
        showImages('.appPics');
    });


    return (
        <div className="content">
            <section className="appTitle row" style={sectionStyle}>
                <div className="titleBox">
                    <h3 id="appName">ShuffleScore</h3>
                    <h6 id="subTitle">Keep Score & Create Tournaments</h6>
                </div>
            </section>
            <div className="descriptionBackground row">
                <div className="appDescription">
                    <p>Now you can track your shuffle board games using this simple but effective scoreboard. Flexible scoring options allow you to play toward point totals of your choosing. You can also quickly create a tournament for your next party by inputing player names to assign brackets and track each players progress as they strive for victory.</p>
                </div>
            </div>
            <div className="screenBackground row">
                <div className="appPics row">
                    <img className="shuffleScreen mx-auto" src="../../images/Shuffle/ShuffleScore_Scoreboard.png" alt="Scoreboard"/>
                    <img className="shuffleScreen mx-auto" src="../../images/Shuffle/ShuffleScore_Tournament.png" alt="Tournament Grid"/>
                </div>
            </div>
            <div className="descriptionBackground row">
                <div className="appDetails row">
                    <div className="space col-lg-5"></div>
                    <div className="appBullets col-lg-5">
                        <h5>Features</h5>
                        <ul>
                            <li>Easily set the target points and keep score for standard shuffleboard games</li>
                            <li>Create a tournament bracket by adding player names to set or randomize brackets</li>
                            <li>Track scores for each tournament game to send the winner onto the next round until a champion is crowned</li>
                        </ul>
                    </div>
                    <div className="space col-lg-2"></div>
                </div>
            </div>



        </div>
    )
}

export default ShufflePage;